import { List } from '@medplum/fhirtypes';
import { create } from 'zustand';
import { ContentFormType, ContentSchema, ContentStatusType } from '../../types/content/contentTypes';
import { v4 as uuidv4 } from 'uuid';
import { EditorState } from 'draft-js';
import { sourceLanguage } from '../../utils/content/contentUtils';

type IFolderType = 'list' | 'large-icons';
const contentIdForCreate = uuidv4();

interface ContentStore {
  status: ContentStatusType;
  searchText: string;
  folderType: IFolderType;
  clickedListItem?: List;
  isFolderModalOpen: boolean;
  selectedContents: string[];
  values: ContentSchema;
  handleSetValues: (values: ContentSchema) => void;
  handleChangeStatus: (newStatus: ContentStatusType | null) => void;
  handleChangeSearch: (value: string) => void;
  toggleFolderType: () => void;
  handleListItemClick: (listItem: List) => void;
  handleCloseFolderModal: () => void;
  handleOpenFolderModal: () => void;
  setSelectedContents: (contents: string[]) => void;
  resetValues: () => void;
  resetStore: () => void;
}

const initialValues = {
  contentId: contentIdForCreate,
  contents: [
    {
      id: undefined,
      isSource: true,
      language: sourceLanguage,
      languageLabel: 'English',
      title: '',
      description: 'Add description',
      status: ContentStatusType.DRAFT,
      content: EditorState.createEmpty(),
    },
  ],
  categoryId: '',
  coverImageFile: null,
  status: ContentStatusType.ALL,
  tags: [],
  formType: ContentFormType.CREATE,
};

const initialState = {
  status: ContentStatusType.ALL,
  searchText: '',
  folderType: 'large-icons' as IFolderType,
  clickedListItem: undefined,
  isFolderModalOpen: false,
  selectedContents: [],
};

export const useContentStore = create<ContentStore>((set) => ({
  ...initialState,
  values: { ...initialValues },
  handleSetValues: (values: ContentSchema) => set({ values }),
  handleChangeStatus: (newStatus: ContentStatusType | null) =>
    set({ status: newStatus ?? ContentStatusType.DRAFT, selectedContents: [] }),
  handleChangeSearch: (value: string) => set({ searchText: value, selectedContents: [] }),
  toggleFolderType: () => set((state) => ({ folderType: state.folderType === 'large-icons' ? 'list' : 'large-icons' })),
  handleListItemClick: (listItem: List) =>
    set((state) =>
      state.clickedListItem?.id === listItem.id
        ? { clickedListItem: undefined, selectedContents: [] }
        : { clickedListItem: listItem, selectedContents: [] }
    ),
  handleCloseFolderModal: () => set({ isFolderModalOpen: false }),
  handleOpenFolderModal: () => set({ isFolderModalOpen: true }),
  setSelectedContents: (contents: string[]) => set({ selectedContents: contents }),
  resetValues: () => set({ values: { ...initialValues } }),
  resetStore: () => set({ ...initialState }),
}));
