import { Box, Center, Loader } from '@mantine/core';
import React from 'react';
import ContentTable from './ContentTable';
import ContentGrid from './ContentGrid';
import { useNavigate } from 'react-router-dom';
import { useContentStore } from '../store/content/useContentStore';
import { useGetContents } from '../queries/content/useGetContents';
import FileExplorer, { FileExplorerIdentifier } from '../file-explorer/FileExplorer';
import { FileExplorerViewType, useFileExplorerStore } from '../store/file-explorer/useFileExplorerStore';
import { IconEditCircle } from '@tabler/icons-react';

interface Props {}

const ContentsPage: React.FC<Props> = () => {
  const [selectedTitle, setSelectedTitle] = React.useState<string>('');
  const navigation = useNavigate();

  // File Explorer Store
  const fileExplorerViewType = useFileExplorerStore((s) => s.fileExplorerViewType);
  const fileExplorerSelectedCategoryId = useFileExplorerStore((s) => s.fileExplorerSelectedCategoryId);

  //Content Store
  const selectedContents = useContentStore((s) => s.selectedContents);

  // Composition[]
  const { data: contentsData, isLoading } = useGetContents();

  const handleRouteToEditPage = (id: string): void => {
    navigation(`/admin/contents/${id}`, {
      state: { selectedCategoryId: fileExplorerSelectedCategoryId },
    });
  };

  console.log(selectedTitle, 'selectedTitle,selectedTitle');
  const handleRouteToCreatePage = (): void => {
    navigation(`/admin/contents/new`, {
      state: { selectedCategoryId: fileExplorerSelectedCategoryId },
    });
  };

  return (
    <FileExplorer
      identifier={FileExplorerIdentifier.CONTENT_CATEGORY}
      icon={<IconEditCircle size={24} />}
      title={'Contents'}
      selectedItems={selectedContents}
      itemsData={contentsData}
      setSelectedTitle={setSelectedTitle}
      handleCreateNew={handleRouteToCreatePage}
    >
      {isLoading ? (
        <Center mih={300}>
          <Loader />
        </Center>
      ) : (
        <Box>
          {fileExplorerViewType === FileExplorerViewType.LIST ? (
            <ContentTable
              title={`${selectedTitle} Contents`}
              contentsData={contentsData}
              onClick={handleRouteToEditPage}
            />
          ) : (
            <ContentGrid
              title={`${selectedTitle} Contents`}
              contentsData={contentsData}
              onClick={handleRouteToEditPage}
            />
          )}
        </Box>
      )}
    </FileExplorer>
  );
};

export default ContentsPage;
