/* eslint-disable react-refresh/only-export-components */
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import {
  ActionIcon,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  Modal,
  Radio,
  Select,
  Tabs,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  IconApps,
  IconFolder,
  IconFolderOpen,
  IconList,
  IconPencil,
  IconPlus,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';
import { Coding, Communication, Composition, List } from '@medplum/fhirtypes';
import { useQueryClient } from 'react-query';
import { useGetCategories } from '../queries/content/useGetCategories';
import { useMoveOrDeleteContentsCategory } from '../queries/content/useMoveOrDeleteContentsCategory';
import { useCreateUpdateCategory } from '../queries/content/useCreateUpdateCategory';
import { showNotification } from '@mantine/notifications';
import { sourceLanguage, targetLangs } from '../utils/content/contentUtils';
import { QueryKeys } from '../queries/query-keys';
import {
  FileExplorerStatus,
  FileExplorerViewType,
  fileExplorerStatusArr,
  useFileExplorerStore,
} from '../store/file-explorer/useFileExplorerStore';

export enum FileExplorerIdentifier {
  QUOTE_CATEGORY = 'quote-category',
  CONTENT_CATEGORY = 'category',
}

interface Props {
  children: ReactNode;
  identifier: FileExplorerIdentifier;
  icon: ReactNode;
  title: string;
  selectedItems: string[];
  itemsData: Composition[] | Communication[];
  handleCreateNew: () => void;
  setSelectedTitle?: Dispatch<SetStateAction<string>>;
}

const FileExplorer: React.FC<Props> = ({
  children,
  icon,
  title,
  selectedItems,
  identifier,
  setSelectedTitle,
  itemsData,
  handleCreateNew,
}) => {
  const theme = useMantineTheme();
  const queryClient = useQueryClient();

  // File Explorer Store
  const fileExplorerLanguage = useFileExplorerStore((s) => s.fileExplorerLanguage);
  const setFileExplorerLanguage = useFileExplorerStore((s) => s.setFileExplorerLanguage);
  const fileExplorerStatus = useFileExplorerStore((s) => s.fileExplorerStatus);
  const setFileExplorerStatus = useFileExplorerStore((s) => s.setFileExplorerStatus);
  const fileExplorerSearchText = useFileExplorerStore((s) => s.fileExplorerSearchText);
  const setFileExplorerSearchText = useFileExplorerStore((s) => s.setFileExplorerSearchText);
  const fileExplorerViewType = useFileExplorerStore((s) => s.fileExplorerViewType);
  const setFileExplorerViewType = useFileExplorerStore((s) => s.setFileExplorerViewType);
  const fileExplorerSelectedCategoryId = useFileExplorerStore((s) => s.fileExplorerSelectedCategoryId);
  const setFileExplorerSelectedCategoryId = useFileExplorerStore((s) => s.setFileExplorerSelectedCategoryId);

  // States
  const [isCreateEditCategorModalOpen, setIsCreateEditCategoryModalOpen] = useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
  const [newCategoryActiveTab, setNewCategoryActiveTab] = useState(targetLangs[0].value);
  const [newCategoryData, setNewCategoryData] = useState<Coding[]>(
    targetLangs.map((t) => {
      return {
        display: '',
        code: t.value,
        system: 'language-code',
      };
    })
  );
  const [categoryModalType, setCategoryModalType] = useState<'create' | 'update'>('create');
  const [selectedListToUpdate, setSelectedListToUpdate] = useState<List | undefined>(undefined);
  const [selectedCategoryToMoveContentsInto, setSelectedCategoryToMoveContentsInto] = useState<string | null>(null);
  const [checkedOptionCategoryDelete, setCheckedOptionCategoryDelete] = useState<'move-contents' | 'delete-contents'>(
    'move-contents'
  );

  // List[] - Params: identifier=category and code=${language}
  const { data: categoriesData } = useGetCategories(identifier, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (resp) => {
      if (resp?.[0]?.id) {
        setFileExplorerSelectedCategoryId(resp?.[0]?.id);
      } else {
        setFileExplorerSelectedCategoryId('');
      }
    },
  });

  const { mutateAsync: moveOrDeleteContentCategory, isLoading: isMoveOrDeleteLoading } =
    useMoveOrDeleteContentsCategory(
      {
        categoryId: fileExplorerSelectedCategoryId,
        categoryMoveId: selectedCategoryToMoveContentsInto,
        compositions: (itemsData as Composition[]) ?? [],
        type: checkedOptionCategoryDelete,
      },
      {
        onSuccess: async () => {
          showNotification({ color: 'green', message: 'Success' });
          await queryClient.invalidateQueries(QueryKeys.GET_CONTENT_CATEGORIES);
        },
        onSettled: () => {
          setIsDeleteCategoryModalOpen(false);
        },
      }
    );

  const handleMoveOrDeleteContentsCategories = async (): Promise<void> => {
    await moveOrDeleteContentCategory({});
  };

  const { mutateAsync: createUpdateCategory, isLoading: isCreateCategoryLoading } = useCreateUpdateCategory({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_CONTENT_CATEGORIES] });
      setIsCreateEditCategoryModalOpen(false);
      setNewCategoryData([]);
    },
  });

  const handleCreateCategory = async (): Promise<void> => {
    await createUpdateCategory({
      type: 'create',
      identifier: FileExplorerIdentifier.CONTENT_CATEGORY,
      coding: newCategoryData,
    });
  };

  const handleUpdateCategory = async (): Promise<void> => {
    await createUpdateCategory({
      type: 'update',
      identifier: FileExplorerIdentifier.CONTENT_CATEGORY,
      coding: newCategoryData,
      selectedListToUpdate,
    });
  };

  const handleSwitchLanguage = (value: string | null): void => {
    if (value) {
      const findLang = targetLangs.find((t) => t.value === value) ?? sourceLanguage;
      setFileExplorerLanguage(findLang);
    }
  };

  const handleChangeNewCategoryTab = (value: string | null): void => {
    setNewCategoryActiveTab(value as string);
  };

  const handleSetCategoryName = (langCode: string | undefined, translatedTitle: string): void => {
    const categoryData = [...newCategoryData];

    const findCategory = categoryData.find((c) => c?.code === langCode);

    if (findCategory) {
      findCategory.display = translatedTitle;
      setNewCategoryData(categoryData);
    }
  };

  const handleClickCreateNewCategory = (): void => {
    setCategoryModalType('create');
    setNewCategoryData(
      targetLangs?.map((t) => {
        return {
          display: '',
          code: t?.value,
          system: 'language-code',
        };
      })
    );
    setIsCreateEditCategoryModalOpen(true);
  };

  const handleClickEditCategory = (category: List): void => {
    setCategoryModalType('update');
    setNewCategoryData(category?.code?.coding ?? []);
    setSelectedListToUpdate(category);
    setIsCreateEditCategoryModalOpen(true);
  };

  // console.log(categoriesData, 'categoriesData');
  return (
    <Card>
      <Grid>
        <Grid.Col span={12}>
          <Center inline p={4} style={{ width: '100%', justifyContent: 'space-between' }}>
            <Center inline>
              {icon}
              <Text size="xl" style={{ marginLeft: 8, fontWeight: 'bold' }}>
                {title}
              </Text>
            </Center>
            <Center inline>
              <Button onClick={handleCreateNew} variant="filled" leftSection={<IconPlus size={18} />}>
                Create new
              </Button>
            </Center>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Center
            inline
            px={12}
            py={10}
            bg={'#F1F3F9'}
            style={{ width: '100%', justifyContent: 'space-between', borderRadius: 5 }}
          >
            <Center inline>
              <TextInput
                leftSection={<IconSearch size={16} />}
                size="sm"
                style={{ minWidth: 300 }}
                mr={8}
                placeholder="Search..."
                value={fileExplorerSearchText}
                onChange={(e) => setFileExplorerSearchText(e.target.value)}
              />
              <Select
                defaultValue={FileExplorerStatus.ALL}
                value={fileExplorerStatus}
                onChange={(value) => setFileExplorerStatus(value as FileExplorerStatus)}
                size="sm"
                allowDeselect={false}
                comboboxProps={{ position: 'bottom' }}
                placeholder="Status"
                mr={8}
                data={fileExplorerStatusArr}
                style={{ maxWidth: 120 }}
              />
              <Select
                defaultValue={sourceLanguage.value}
                value={fileExplorerLanguage.value}
                onChange={(value) => handleSwitchLanguage(value)}
                size="sm"
                allowDeselect={false}
                comboboxProps={{ position: 'bottom' }}
                placeholder="Language"
                mr={8}
                data={targetLangs}
                style={{ maxWidth: 120 }}
              />
              {selectedItems.length > 0 && (
                <Button color="red" variant="filled" size="sm" leftSection={<IconTrash size={18} />}>
                  Delete
                </Button>
              )}
            </Center>
            <Center inline>
              <Tooltip label={fileExplorerViewType === FileExplorerViewType.LIST ? 'Grid view' : 'List view'}>
                <ActionIcon
                  variant="subtle"
                  onClick={() =>
                    setFileExplorerViewType(
                      fileExplorerViewType === FileExplorerViewType.LIST
                        ? FileExplorerViewType.GRID
                        : FileExplorerViewType.LIST
                    )
                  }
                >
                  {fileExplorerViewType === FileExplorerViewType.LIST ? (
                    <IconApps color={theme.colors.blue[6]} />
                  ) : (
                    <IconList color={theme.colors.blue[6]} />
                  )}
                </ActionIcon>
              </Tooltip>
            </Center>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Center inline style={{ marginBottom: 12 }}>
            <Button
              onClick={handleClickCreateNewCategory}
              size="sm"
              variant="light"
              leftSection={<IconPlus size={18} />}
              mr={12}
            >
              Create new category
            </Button>
          </Center>
          {categoriesData && fileExplorerSelectedCategoryId && (
            <Tabs
              value={fileExplorerSelectedCategoryId}
              onChange={(val) => setFileExplorerSelectedCategoryId(val as string)}
              orientation="vertical"
              mih={'66vh'}
            >
              <Tabs.List>
                {categoriesData?.map((item: any) => {
                  const findTitle =
                    item?.code?.coding?.find((x: Coding) => x?.code === fileExplorerLanguage.value)?.display ?? '--';

                  if (item?.id === fileExplorerSelectedCategoryId) {
                    setSelectedTitle?.(
                      item?.code?.coding?.find((x: Coding) => x?.code === fileExplorerLanguage.value)?.display ?? '--'
                    );
                  }

                  return (
                    <Tabs.Tab
                      key={item?.id}
                      leftSection={
                        item?.id === fileExplorerSelectedCategoryId ? (
                          <Flex direction="row" align="center">
                            <IconFolderOpen size={20} color={theme.colors.blue[6]} />
                            <Text
                              ml={4}
                              color={fileExplorerSelectedCategoryId === item?.id ? theme.colors.blue[6] : 'unset'}
                            >
                              {findTitle?.length >= 22 ? `${findTitle?.slice(0, 22)}..` : findTitle}
                            </Text>
                          </Flex>
                        ) : (
                          <Flex direction="row" align="center">
                            <IconFolder size={20} />
                            <Text
                              ml={4}
                              color={fileExplorerSelectedCategoryId === item?.id ? theme.colors.blue[6] : 'unset'}
                            >
                              {findTitle?.length >= 22 ? `${findTitle?.slice(0, 22)}..` : findTitle}
                            </Text>
                          </Flex>
                        )
                      }
                      rightSection={
                        <Flex>
                          <ActionIcon
                            onClick={() => handleClickEditCategory(item)}
                            variant="subtle"
                            color={theme.colors.blue[8]}
                          >
                            <IconPencil size={16} />
                          </ActionIcon>
                          <ActionIcon
                            onClick={() => setIsDeleteCategoryModalOpen(true)}
                            variant="subtle"
                            color={theme.colors.red[8]}
                          >
                            <IconTrash size={16} />
                          </ActionIcon>
                        </Flex>
                      }
                      style={{
                        borderBottom: '1px solid #f3f3f3',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      value={item?.id ?? ''}
                    />
                  );
                })}
              </Tabs.List>
              <Tabs.Panel
                key={fileExplorerSelectedCategoryId}
                value={fileExplorerSelectedCategoryId as string}
                p={12}
                style={{ width: '100%' }}
              >
                {children}
              </Tabs.Panel>
            </Tabs>
          )}
        </Grid.Col>
      </Grid>

      {/* CREATE NEW CATEGORY & EDIT CATEGORY MODAL */}
      <Modal
        title={categoryModalType === 'create' ? 'Create new category' : 'Update category'}
        opened={isCreateEditCategorModalOpen}
        onClose={() => setIsCreateEditCategoryModalOpen(false)}
        closeOnClickOutside
        centered
        size="md"
      >
        <Flex direction="column">
          <Tabs value={newCategoryActiveTab} onChange={handleChangeNewCategoryTab}>
            <Tabs.List>
              {targetLangs.map((t) => {
                return (
                  <Tabs.Tab key={t.value} value={t.value}>
                    {t.label}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>

            <Tabs.Panel key={newCategoryActiveTab} value={newCategoryActiveTab}>
              <TextInput
                size="md"
                mt={20}
                style={{ width: '100%' }}
                label="Category name"
                value={newCategoryData.find((n) => n.code === newCategoryActiveTab)?.display}
                placeholder="Give a name to your category"
                onChange={(e) =>
                  handleSetCategoryName(
                    newCategoryData.find((n) => n.code === newCategoryActiveTab)?.code,
                    e.target.value
                  )
                }
              />
            </Tabs.Panel>
          </Tabs>
          <Flex align="center" justify="flex-end" direction="row" mt={18}>
            <Button variant="outline" onClick={() => setIsCreateEditCategoryModalOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isCreateCategoryLoading}
              onClick={categoryModalType === 'create' ? handleCreateCategory : handleUpdateCategory}
              disabled={newCategoryData.some((c) => (c.display as string).length <= 0)}
              variant="filled"
              bg={theme.primaryColor}
              ml={8}
            >
              {categoryModalType === 'create' ? 'Create' : 'Update'}
            </Button>
          </Flex>
        </Flex>
      </Modal>

      {/* DELETE CATEGORY MODAL */}
      <Modal
        title="Delete category"
        opened={isDeleteCategoryModalOpen}
        onClose={() => setIsDeleteCategoryModalOpen(false)}
        closeOnClickOutside
        centered
        size={itemsData?.length > 0 ? 'lg' : 'sm'}
      >
        <Divider />
        <Flex direction="column" my={12}>
          {itemsData?.length > 0 ? (
            <Flex direction="column">
              <Text>
                This category has contents. Would you like to move all contents or delete all contents and the category?
              </Text>
              <Flex my={12} direction="row" style={{ width: '100%' }}>
                <Radio
                  label="Move contents"
                  size="sm"
                  checked={checkedOptionCategoryDelete === 'move-contents'}
                  onChange={() => setCheckedOptionCategoryDelete('move-contents')}
                  style={{ marginRight: 12 }}
                />
                <Radio
                  label="Delete contents"
                  size="sm"
                  checked={checkedOptionCategoryDelete === 'delete-contents'}
                  onChange={() => setCheckedOptionCategoryDelete('delete-contents')}
                />
              </Flex>
              {checkedOptionCategoryDelete === 'move-contents' && (
                <Select
                  size="sm"
                  comboboxProps={{ position: 'bottom' }}
                  data={categoriesData?.map((x: any) => {
                    return {
                      label:
                        x?.code?.coding?.find((c: Coding) => c?.code === fileExplorerLanguage.value)?.display ?? '',
                      value: x?.id,
                    };
                  })}
                  label="Move contents to"
                  onChange={(val) => setSelectedCategoryToMoveContentsInto(val)}
                />
              )}
              <Flex align="center" justify="flex-end" direction="row" mt={18}>
                <Button variant="outline" onClick={() => setIsDeleteCategoryModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  loading={isMoveOrDeleteLoading}
                  onClick={handleMoveOrDeleteContentsCategories}
                  variant="filled"
                  bg={theme.primaryColor}
                  ml={8}
                >
                  {checkedOptionCategoryDelete === 'move-contents'
                    ? 'Move contents & delete category'
                    : 'Delete category & contents'}
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex w={'100%'} direction="column">
              <Text>
                Are you sure to delete{' '}
                {categoriesData?.find((x: any) => x?.id === fileExplorerSelectedCategoryId)?.title} {` `}
                category?
              </Text>
              <Flex align="center" justify="flex-end" direction="row" mt={18}>
                <Button variant="outline" onClick={() => setIsCreateEditCategoryModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  loading={isMoveOrDeleteLoading}
                  onClick={handleMoveOrDeleteContentsCategories}
                  variant="filled"
                  bg={theme.primaryColor}
                  ml={8}
                >
                  Delete category
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Modal>
    </Card>
  );
};

export default FileExplorer;
