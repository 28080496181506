import React from 'react';
import { useCreateUpdateContent } from '../queries/content/useCreateUpdateContent';
import { ContentForm } from './ContentForm';
import { Binary } from '@medplum/fhirtypes';
import { useContentStore } from '../store/content/useContentStore';
import { LoadingOverlay } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { sourceLanguage } from '../utils/content/contentUtils';
import { useLocation } from 'react-router-dom';

interface Props {}

const CreateContentPage: React.FC<Props> = () => {
  const medplum = useMedplum();
  const values = useContentStore((s) => s.values);
  const resetValues = useContentStore((s) => s.resetValues);
  const location = useLocation();
  const { selectedCategoryId } = location.state || {};

  React.useEffect(() => {
    resetValues();
  }, );

  const { mutateAsync: createContent, isLoading: isCreateUpdateContentLoading } = useCreateUpdateContent(values, {
    onSettled: async () => {
      setSelectedLanguage(sourceLanguage);
    },
  });

  const [selectedLanguage, setSelectedLanguage] = React.useState<{ label: string; value: string }>(sourceLanguage);

  const handleUploadCoverImage = async (file: File): Promise<string | undefined> => {
    const binary: Binary = await medplum.createBinary(file, file.name, file.type);

    return binary.url as string;
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const coverImageUrl = await handleUploadCoverImage(values?.coverImageFile as File);

      await createContent({ coverImageUrl });
    } catch (error) {
      console.error({ error });
    }
  };

  if (isCreateUpdateContentLoading) {
    return <LoadingOverlay visible={isCreateUpdateContentLoading} overlayProps={{ blur: 2 }} />;
  }

  return (
    <ContentForm
      onSubmit={handleSubmit}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      selectedCategoryId={selectedCategoryId}
    />
  );
};

export default CreateContentPage;
