import * as React from 'react';
import { Button, CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from '@mantine/core';
import { useContentStore } from '../store/content/useContentStore';
import { useGetTagsByName } from '../queries/content/useGetTagsByName';
import { useQueryClient } from 'react-query';
import { useCreateTag } from '../queries/content/useCreateTag';
import { CatalogEntry } from '@medplum/fhirtypes';
import { QueryKeys } from '../queries/query-keys';
import { IconPlus } from '@tabler/icons-react';
import { Loading } from '@medplum/react';

interface TagSelectionProps {
  errorText?: string;
  disabled: boolean;
}

export const TagSelection: React.FC<TagSelectionProps> = ({ disabled }) => {
  const queryClient = useQueryClient();

  const [searchValue, onSearchChange] = React.useState('');
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const { data: tagsData, isLoading } = useGetTagsByName(searchValue);

  const { mutateAsync: createTag } = useCreateTag({
    onSuccess: async (resp: CatalogEntry) => {
      setValues({
        ...values,
        tags: [...values.tags, { value: resp?.id as string, label: resp?.type?.coding?.[0]?.display as string }],
      });

      await queryClient.invalidateQueries(QueryKeys.GET_TAGS_BY_NAME);
    },
  });

  const handleCreateTag = async (): Promise<void> => {
    const isNewTagExists =
      values?.tags?.find((t) => t?.label === searchValue) ||
      tagsData?.find((tt: CatalogEntry) => tt?.type?.coding?.[0]?.display === searchValue);
    if (isNewTagExists) {
      return;
    }

    await createTag({ title: searchValue });
  };

  const handleRemoveItem = (value: string): void => {
    const filterTags = values.tags?.filter((t) => t?.value !== value);

    setValues({ ...values, tags: [...filterTags] });
  };

  const handleAddItem = (value: string): void => {
    const isTagExists = values?.tags?.find((t) => t?.value === value);

    if (isTagExists) {
      handleRemoveItem(value);
      return;
    }

    const findTag = tagsData?.find((t: { label: string; value: string }) => t?.value === value);

    setValues({ ...values, tags: [...values.tags, findTag] });
  };

  const tagValues = values?.tags?.map((item) => (
    <Pill key={item.value} withRemoveButton onRemove={() => handleRemoveItem(item.value)}>
      {item.label}
    </Pill>
  ));

  const options = tagsData
    ?.filter((item: { label: string; value: string }) =>
      item.label.toLowerCase().includes(searchValue.trim().toLowerCase())
    )
    ?.map((item: { label: string; value: string }) => (
      <Combobox.Option value={item.value} key={item.value} active={searchValue.includes(item.value)}>
        <Group gap="sm">
          {values?.tags?.some((t) => t?.value === item?.value) && <CheckIcon size={12} />}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox disabled={disabled} store={combobox} onOptionSubmit={handleAddItem}>
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {tagValues}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={searchValue}
                placeholder="Add tag"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  onSearchChange(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && searchValue?.length === 0) {
                    event.preventDefault();
                    // handleValueRemove(event.target.value);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown style={{ maxHeight: 400, overflowY: 'scroll' }}>
        <Combobox.Options>
          {isLoading && <Loading />}
          {options?.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>
              <Button
                onClick={handleCreateTag}
                leftSection={<IconPlus size={14} />}
                size="sm"
                variant="light"
                fullWidth
              >
                Create '{searchValue}' tag
              </Button>
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
